import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import LogoutButton from "./LogoutButton";
import TranslateComponent from "./TranslateComponent";

const ClientNavbar = ({ languageCode, navbarLinks }) => {
	return (
		<section
			className="position-relative"
			style={{ borderBottom: "1px solid #00304B" }}
		>
			<Container className="py-md-3 py-2">
				<Row className="align-items-center">
					<Col
						xs={12}
						md={4}
						className="text-center text-md-start mb-2 mb-md-0"
					>
						<Link to="/">
							<StaticImage
								src="../images/icon.png"
								alt="Premier Support Services Logo"
								style={{ maxWidth: "150px" }}
								quality={100}
							/>
						</Link>
					</Col>
					<Col xs={12} md={8}>
						{/* Desktop Nav */}
						<Nav className="justify-content-center d-none d-md-flex justify-content-md-end">
							{navbarLinks &&
								navbarLinks.map((item, index) => {
									const { url, title, target } = item.link;
									const isLocal = url.startsWith("/");

									return isLocal ? (
										<Nav.Link
											key={index}
											as={Link}
											to={url}
											className="outfit-regular"
										>
											<TranslateComponent code={languageCode} text={title} />
										</Nav.Link>
									) : (
										<Nav.Link
											key={index}
											href={url}
											target={target || "_blank"}
											className="outfit-regular"
											rel="noopener noreferrer"
										>
											<TranslateComponent code={languageCode} text={title} />
										</Nav.Link>
									);
								})}
							<LogoutButton />
						</Nav>

						{/* Mobile Nav */}
						<Nav className="justify-content-center d-md-none justify-content-md-end">
							{navbarLinks &&
								navbarLinks.map((item, index) => {
									const { url, title, target } = item.link;
									const isLocal = url.startsWith("/");

									return isLocal ? (
										<Nav.Link
											key={index}
											as={Link}
											to={url}
											style={{ fontSize: "90%" }}
											className="outfit-regular"
										>
											<TranslateComponent code={languageCode} text={title} />
										</Nav.Link>
									) : (
										<Nav.Link
											key={index}
											href={url}
											target={target || "_blank"}
											rel="noopener noreferrer"
											style={{ fontSize: "90%" }}
											className="outfit-regular"
										>
											<TranslateComponent code={languageCode} text={title} />
										</Nav.Link>
									);
								})}
						</Nav>
						<div className="d-md-none position-absolute top-0 end-0 ms-auto p-2">
							<LogoutButton />
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default ClientNavbar;
