import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";

const HubHero = ({ heading, body, image, customerName, afterText }) => {
	const textColumnRef = useRef(null);

	return (
		<section className="">
			<div style={{ minHeight: "100%" }} xs={12} className=" mb-4  d-md-none">
				<img src={image} alt={heading} className="w-100" />
			</div>
			<Container fluid className="h-100 px-md-0">
				<Row className=" g-4 g-md-0  h-100">
					{/* Text Content */}
					<Col
						md={6}
						lg={5}
						className="py-lg-5 py-xl-9 text-center text-md-start "
						ref={textColumnRef}
					>
						<div className="py-xl-6  py-md-5 ps-xl-6  ps-md-5 pe-lg-0 pe-md-4">
							<h1 className="text-primary display-4 mb-4">
								{heading} {customerName || ""} {afterText || ""}
							</h1>
							{body && <p>{body}</p>}
						</div>
					</Col>

					{/* Background Image Column */}
					<Col
						style={{ minHeight: "100%" }}
						md={6}
						lg={7}
						className="d-none d-md-block"
					>
						<div
							style={{
								height: "100%",
								backgroundImage: `url(${image})`,
								backgroundSize: "cover",
								backgroundPosition: "center",
								clipPath: "polygon(25% 0, 100% 0, 100% 100%, 0% 100%)",
							}}
						></div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default HubHero;
