import { CookieBanner } from "@connorrjm/rjm-cookie-banner";
import React from "react";
import ClientNavbar from "./client-navbar";
import HubFooter from "./HubFooter";

const ClientLayout = ({ children, languageCode, navLinks }) => {
	return (
		<>
			<ClientNavbar navbarLinks={navLinks} languageCode={languageCode} />
			<CookieBanner url="/cookie-notice" />
			{children}
			<HubFooter languageCode={languageCode} />
		</>
	);
};

export default ClientLayout;
