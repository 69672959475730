import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import AnnouncementsList from "../../components/AnnouncementsList";
import ClientLayout from "../../components/client-Layout";
import HubHero from "../../components/HubHero";
import SplashScreen from "../../components/SplashScreen";
import TranslateComponent from "../../components/TranslateComponent";
import TranslationBanner from "../../components/TranslationBanner";
import withAuth from "../../components/withAuth";

const AnnouncementsClient = ({ data }) => {
	const [clientData, setClientData] = useState(null);
	const [languageCode, setLanguageCode] = useState("en");
	const [heroData, setHeroData] = useState({
		heading: "",
		body: "",
		image: "",
	});
	const [translatedFaqs, setTranslatedFaqs] = useState([]);

	const handleLanguageChange = (code) => {
		setLanguageCode(code);
	};

	const fetchData = async (endpoint) => {
		const token = localStorage.getItem("token");

		try {
			const response = await fetch("/.netlify/functions/fetchData", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ endpoint, token }),
			});

			const result = await response.json();

			if (!response.ok) {
				console.error(`Error fetching ${endpoint} data:`, result.message);
				return null;
			}

			return result;
		} catch (error) {
			console.error("Fetch error:", error);
			return null;
		}
	};

	useEffect(() => {
		const getData = async () => {
			const result = await fetchData("client?_fields=acf&acf_format=standard");

			if (result) {
				setClientData(result);
				// Set the hero data after fetching
				const heroSection = result[0]?.acf?.hero_section;
				setHeroData({
					heading: heroSection?.heading || "",
					body: heroSection?.body || "",
					image: heroSection?.image || "",
				});
			} else {
				setClientData(null);
			}
		};

		getData();
	}, []);

	// Extract data from GraphQL query
	const getInTouchNode = data.allWpClientPage.edges[0]?.node;
	const announcementsFields = getInTouchNode?.announcementsFields;
	const pageHeading = announcementsFields?.heading || "";
	const pageBody = announcementsFields?.body || "";
	const heroImage = announcementsFields?.image.sourceUrl || "";
	const announcements = announcementsFields?.announcements || [];

	return (
		<div>
			{clientData ? (
				<ClientLayout
					navLinks={clientData[0]?.acf.navbar_links}
					languageCode={languageCode}
				>
					<div>
						<div className="d-md-none">
							<TranslationBanner
								languageCode={languageCode}
								onSelectLanguage={handleLanguageChange}
							/>
						</div>
						<HubHero
							heading={
								<TranslateComponent code={languageCode} text={pageHeading} />
							}
							body={<TranslateComponent code={languageCode} text={pageBody} />}
							image={heroImage}
						/>
						<div className="d-none d-md-block">
							<TranslationBanner
								languageCode={languageCode}
								onSelectLanguage={handleLanguageChange}
							/>
						</div>
						<AnnouncementsList
							announcements={announcements}
							languageCode={languageCode}
						/>
					</div>
				</ClientLayout>
			) : (
				<SplashScreen />
			)}
		</div>
	);
};

export const query = graphql`
	query {
		allWpClientPage(filter: { slug: { eq: "announcements" } }) {
			edges {
				node {
					id
					announcementsFields {
						body
						heading
						image {
							sourceUrl
						}
						announcements {
							file {
								title
								mediaItemUrl
								date
							}
						}
					}
				}
			}
		}
	}
`;

export default withAuth(AnnouncementsClient, "client");
